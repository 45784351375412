import React, { useContext, useState } from "react";

import AppContext from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useRef } from "react";

export default function Programs() {
  const context = useContext(AppContext);
  const { setUserData, userdata, selectedProgram, setSelectedProgram } =
    context;
  const navigate = useNavigate();

  return (
    <div>
      <div className="" style={{ position: "relative" }}>
        <div
          className="cart-container px-3"
          style={{
            position: "fixed",
            bottom: 10,
            height: 100,
            width: "100%",
            zIndex: 999,
            display: selectedProgram ? "block" : "none",
          }}
        >
          <div
            className="cart-wrapper border p-1"
            style={{
              backgroundColor: "white",
              height: "100%",
              borderRadius: 15,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              className="cp-info simpleflex"
              style={{ height: "100%", flex: 3, flexDirection: "column" }}
            >
              <div className="simpleflex">
                <span className="text-muted small">PROGRAM</span>
              </div>
              <div className="simpleflex">
                <span className="" style={{ fontSize: 17, fontWeight: 500 }}>
                  {selectedProgram == 1 ? "Wash" : ""}
                  {selectedProgram == 2 ? "Wash + Iron" : ""}
                  {selectedProgram == 3 ? "Iron" : ""}
                </span>
              </div>
            </div>
            <div
              className="cp-rate simpleflex"
              style={{ height: "100%", flex: 3, flexDirection: "column" }}
            >
              <div className="simpleflex">
                <span className="text-muted small">RATE</span>
              </div>
              <div className="simpleflex">
                <span className="" style={{ fontSize: 17, fontWeight: 500 }}>
                  &#8377;38/kg
                </span>
              </div>
            </div>
            <div
              className="cp-btn-wrapper simpleflex"
              style={{ height: "100%", flex: 4 }}
            >
              <button
                className="btn btn-primary"
                onClick={() => {
                  navigate("/stages");
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
        <div className="container"></div>
      </div>
    </div>
  );
}
