import React, { useState, useRef } from "react";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import Address from "../stagescomponents/Address";
import NewAddressSection from "./NewAddressSection";

export default function PickupAddress() {
  const context = useContext(AppContext);
  const {
    pickupAdd,
    userdata,
    allAddress,
    setPickupAdd,
    sendDataToApp,
  } = context;


  function pickupAddFunc(aid) {
    setPickupAdd(aid);
  }
  return (
    <div>
      <div>
        {userdata.loggedin !== false && <NewAddressSection />}

        <div className="stage-address-wrapper">
          {userdata.loggedin === true &&
            allAddress.map((item) => {
              return (
                <div
                  className={`address-wrapper p-2 my-2 border ${
                    pickupAdd === item.addressid ? "border-primary" : ""
                  }`}
                  key={item.addressid}
                >
                  <Address data={item} setpickup={pickupAddFunc} />
                </div>
              );
            })}
          {allAddress.length == 0 && userdata.loggedin === true && (
            <div className="simpleflex py-3 text-muted">
              <span style={{ fontSize: 14 }}>You have no saved address</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
