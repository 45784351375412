import React, { useState } from "react";
import app from "../../firebaseConfig";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { useNavigate } from "react-router-dom";

export default function Verification() {
  const auth = getAuth(app);
  const context = useContext(AppContext);
  const {
    sendDataToApp,
    setSignupFieldDetails,
    signupconfirmRes,
    setSignupConfirmRes,
    signupFieldDetails,
    signupErrorDetails,
    setSignupErrors,
    signupUser,
  } = context;
  const [pageStage, setPageStage] = useState(1);
  const [validateCodeError, setValidateCodeError] = useState(false);
  const [verificatioAttempt, setVerificationAttempt] = useState(3);
  const [stageTwoLoader, setStageTwoLoader] = useState(false);
  const [resendTime, setResendTime] = useState("30");
  const [resendState, setResendState] = useState(false);
  const navigate = useNavigate();
  function onchange(e) {
    if (e.target.id == "mobile") {
      if (signupconfirmRes) {
        setSignupConfirmRes(null);
      }
      if (e.target.value.length <= 10) {
        setSignupFieldDetails({
          ...signupFieldDetails,
          [e.target.id]: e.target.value,
        });
      }
    } else {
      setSignupFieldDetails({
        ...signupFieldDetails,
        [e.target.id]: e.target.value,
      });
    }
  }
  const cancelValidation = async () => {
    setPageStage("loading");
    setSignupFieldDetails({
      name: "",
      mobile: "",
      email: "",
      password: "",
      referral: "",
      verification: "",
    });
    setSignupConfirmRes(null);
    setSignupErrors({
      password: false,
      name: false,
      mobileLen: false,
      mobileExists: false,
      email: false,
      verification: false,
    });
    setTimeout(() => {
      navigate("/signup");
      setPageStage(1);
    }, 1000);
  };
  const validateCode = async () => {
    try {
      let res = await signupconfirmRes.confirm(signupFieldDetails.verification);
      return res;
    } catch (error) {
      if (error.message.includes("auth/code-expired")) {
        setValidateCodeError(true);
        cancelValidation();
        sendDataToApp({
          alert: {
            type: "red",
            message: "Out of attempts, try again later",
            duration: 5000,
          },
        });
      }
      return null;
    }
  };
  const validateFunc = async () => {
    setStageTwoLoader(true);
    setSignupErrors((stats) => {
      return { ...stats, verification: false };
    });

    let verificationErr = false;

    // validating verification field
    if (signupFieldDetails.verification.length < 6) {
      // enter a valid name
      setSignupErrors((stat) => {
        return { ...stat, verification: true };
      });
      verificationErr = true;
    } else {
      verificationErr = false;
    }
    if (!verificationErr && verificatioAttempt != 0) {
      setVerificationAttempt((stat) => {
        return stat - 1;
      });
      let userCred = await validateCode();

      if (userCred) {
        let mobile = signupFieldDetails.mobile;
        let name = signupFieldDetails.name;
        let password = signupFieldDetails.password;
        let referral = signupFieldDetails.referral;
        let signupData = {};
        if (referral != "") {
          signupData = { name, mobile, password, referral };
        } else {
          signupData = { name, mobile, password };
        }

        const response = await signupUser(signupData);

        if (response.response == "success") {
          sendDataToApp({
            response: {
              response: "success",
              message: "Account Created",
              data: response.data,
            },
          });
        }
        if (response.response == "error") {
          sendDataToApp({
            response: {
              response: "error",
              message: response.message,
              data: null,
            },
          });
        }
      } else {
        setSignupErrors((stat) => {
          return { ...stat, verification: true };
        });
      }
    }
    setStageTwoLoader(false);
  };
  const resendOTP = async () => {
    setStageTwoLoader(true);

    window.recaptchaVerifier.render().then(function (widgetId) {
      window.grecaptcha.reset(widgetId);
    });
    signInWithPhoneNumber(
      auth,
      `+91${signupFieldDetails.mobile}`,
      window.recaptchaVerifier
    )
      .then((confirmationResult) => {
        setSignupConfirmRes(confirmationResult);
        setStageTwoLoader(false);
        restartTimer();
        sendDataToApp({
          alert: {
            type: "green",
            message: `SMS sent successfully on +91${signupFieldDetails.mobile}`,
            duration: 5000,
          },
        });
        setVerificationAttempt(3);
      })
      .catch((error) => {
        restartTimer();
        sendDataToApp({
          alert: {
            type: "red",
            message: `Something went wrong while sending SMS on +91${signupFieldDetails.mobile}, resend again`,
            duration: 5000,
          },
        });
        setStageTwoLoader(false);
      });
  };
  useEffect(() => {
    if (!resendState) {
      const timer = setInterval(() => {
        if (resendTime <= 10) {
          setResendTime((stat) => {
            return `0${stat - 1}`;
          });
        } else {
          setResendTime((stat) => {
            return stat - 1;
          });
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [resendTime]);
  const restartTimer = () => {
    setResendTime("30");
    setResendState(false);
  };
  useEffect(() => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "resendotp",
        {
          size: "invisible",
          callback: (response) => {},
          "expired-callback": (res) => {
            sendDataToApp({
              alert: {
                type: "red",
                message: "Please refresh the page and try again",
                duration: 5000,
              },
            });
          },
        },
        auth
      );
    } catch (error) {
      sendDataToApp({
        alert: {
          type: "red",
          message:
            "This is a development error, complaint it at complaint@shortwash.com or restart the app",
          duration: 5000,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!signupconfirmRes) {
      cancelValidation();
    }
  }, [signupconfirmRes]);
  useEffect(() => {
    if (resendTime <= 0) {
      setResendState(true);
    }
  }, [resendTime]);
  return (
    <React.Fragment>
      {pageStage == 1 && (
        <div
          className="container simpleflex"
          style={{ height: "100vh", flexDirection: "column" }}
        >
          <div className="text-center" style={{ marginBottom: 25 }}>
            <span style={{ fontSize: 23 }}>Enter verification code</span>
          </div>
          <div style={{ position: "relative", marginTop: 20 }} className="w-75">
            <span
              style={{
                fontSize: 15,
                color: signupErrorDetails.verification ? "red" : "grey",
                position: "absolute",
                backgroundColor: "white",
                paddingLeft: 5,
                paddingRight: 5,
                zIndex: 1,
                left: 12,
                top: -4,
              }}
            >
              Verification Code
            </span>
            <input
              style={{
                borderColor: "rgb(225,225,225)",
                borderWidth: 1,
                marginTop: 6,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                width: "100%",
                color: "black",
                fontSize: 16,
                borderColor: signupErrorDetails.verification
                  ? "red"
                  : "rgb(225,225,225)",
              }}
              placeholder="One time password"
              className="form-control"
              type="number"
              id="verification"
              value={signupFieldDetails.verification}
              onChange={onchange}
            />
            <div
              style={{
                display: signupErrorDetails.verification ? "flex" : "none",
                flexDirection: "row",
                color: "red",
                marginTop: 5,
                alignItems: "center",
              }}
            >
              <img
                style={{
                  height: 12,
                  width: 12,
                  marginRight: 5,
                }}
                src={require("../../assets/signup/info_red.png")}
              />
              <span style={{ color: "red", fontSize: 14 }}>
                Verification OTP is invalid
              </span>
            </div>
            {!resendState && (
              <div
                className="d-flex justify-content-end"
                style={{ marginTop: 8 }}
              >
                <span style={{ fontSize: 14 }}>
                  resend otp in{" "}
                  <span style={{ color: "red" }}>00:{resendTime}</span>
                </span>
              </div>
            )}
            {resendState && (
              <div
                className="d-flex justify-content-end"
                style={{ marginTop: 8 }}
              >
                <span style={{ fontSize: 14 }} onClick={resendOTP}>
                  resend otp?
                </span>
              </div>
            )}
          </div>
          {/*error*/}

          {stageTwoLoader && (
            <div className="simpleflex " style={{ marginTop: 30 }}>
              <img
                src={require("../../assets/stages/pageLoader.gif")}
                alt="Page Loading Animation"
              />
            </div>
          )}
          <div className="w-75" style={{ marginTop: 25 }}>
            <button
              className="btn w-100"
              style={{ backgroundColor: "red", color: "white" }}
              onClick={validateFunc}
            >
              Validate ({verificatioAttempt} Attempts)
            </button>
            <button
              className="btn btn-outline-dark w-100 mt-3"
              onClick={cancelValidation}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {pageStage == "loading" && (
        <div className="simpleflex" style={{ height: "100vh" }}>
          <img
            src={require("../../assets/stages/pageLoader.gif")}
            alt="Page Loading Animation"
          />
        </div>
      )}
      <div id="resendotp"></div>
    </React.Fragment>
  );
}
