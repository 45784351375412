import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import React from 'react';
import Home from './components/Home'
import Stages from './components/Stages'
import Programs from './components/Programs'
import Overview from './components/Overview'
import Confirm from './components/Confirm'
import AppState from "./context/AppState"
import Reviews from './components/Reviews';
import SepReview from './components/SepReview';
import Signup from './components/auth/Signup';
import Verification from './components/auth/Verification';
import ForgotPassword from './components/auth/ForgotPassword';
import Newpassword from './components/auth/Newpassword';

function App() {
  const router = createBrowserRouter([
    { 
      path: "/",
      element: <Home/>,
    },
    {
      path: "/home",
      element: <Home/>,
    },
    {
      path: "/stages",
      element: <Stages/> ,
    },
    {
      path: "/programs",
      element: <Programs/> ,
    },
    {
      path: "/overview",
      element: <Overview/>,
    },
    {
      path: "/confirm",
      element: <Confirm/>,
    },
    {
      path: "/reviews",
      element: <Reviews/>,
    },
    {
      path: "/sepreview",
      element: <SepReview/>,
    },
    {
      path: "/signup",
      element: <Signup/>
    },
    {
      path: "/verification",
      element: <Verification/>
    },
    {
      path: "/forgotpassword",
      element: <ForgotPassword/>
    },
    {
      path: "/newpassword",
      element: <Newpassword/>
    }
  ]);
  return (
    <AppState>
    <React.Fragment>
    <RouterProvider router={router} />
    </React.Fragment>
    </AppState>
  );
}

export default App;
