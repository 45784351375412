import React, { useState } from "react";
import Star from "../assets/review/star24.png";
import StarBig from "../assets/review/star.png";
import HalfStar from "../assets/review/halfstar24.png";
import BlankStar from "../assets/review/star_blank24.png";
import BlankStarBig from "../assets/review/star_blank.png";
import UserReview from "./homecomponents/UserReview";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import Spinner from "./Spinner";

export default function Reviews() {
  const context = useContext(AppContext);
  const {
    allReviews,
    hreviewloading,
    addReview,
    addingReviewLoading,
    getAllReviews,
    sendDataToApp,
    userdata,
    setUserData,
    setAllReviews,
    sethreviewloading,
  } = context;
  const [averageRating, setAverageRating] = useState(0);
  const [fiveStarBar, setFiveStarBar] = useState(0);
  const [fourStarBar, setFourStarBar] = useState(0);
  const [threeStarBar, setThreeStarBar] = useState(0);
  const [twoStarBar, setTwoStarBar] = useState(0);
  const [oneStarBar, setOneStarBar] = useState(0);
  const [pageStage, setPageStage] = useState("loading");
  const navigate = useNavigate();
  const [shareReviewStars, setShareReviewStars] = useState(0);
  const [reviewMessage, setReviewMessage] = useState("");
  const [reviewResult, setReviewResult] = useState(false);
  const [reviewError, setReviewError] = useState({
    starError: false,
    messageError: false,
  });
  const reviewModal = useRef();

  useEffect(() => {
    // summing star_rating
    let total_rating = 0;
    for (let z = 0; z < allReviews.length; z++) {
      const element = allReviews[z];
      let star_rating = parseFloat(element.star_rating);
      total_rating += star_rating;
    }
    let average_rating = total_rating / allReviews.length;
    setAverageRating((Math.round(average_rating * 100) / 100).toFixed(1));

    // calculating 5 stars for excellent bar
    let fiveArr = allReviews.filter((item) => {
      return item.star_rating == 5;
    });
    let fivePercent = (fiveArr.length * 100) / allReviews.length;
    setFiveStarBar(fivePercent);

    // calculating 4 stars for excellent bar
    let fourArr = allReviews.filter((item) => {
      return item.star_rating == 4;
    });
    let fourPercent = (fourArr.length * 100) / allReviews.length;
    setFourStarBar(fourPercent);

    // calculating 3 stars for excellent bar
    let threeArr = allReviews.filter((item) => {
      return item.star_rating == 3;
    });
    let threePercent = (threeArr.length * 100) / allReviews.length;
    setThreeStarBar(threePercent);

    // calculating 2 stars for excellent bar
    let twoArr = allReviews.filter((item) => {
      return item.star_rating == 2;
    });
    let twoPercent = (twoArr.length * 100) / allReviews.length;
    setTwoStarBar(twoPercent);

    // calculating 1 stars for excellent bar
    let oneArr = allReviews.filter((item) => {
      return item.star_rating == 1;
    });
    let onePercent = (oneArr.length * 100) / allReviews.length;
    setOneStarBar(onePercent);

    setTimeout(() => {
      setPageStage("loaded");
    }, 1000);
  }, [allReviews]);
  function setStars(parm) {
    if (averageRating >= parm) {
      return Star;
    }
    if (averageRating > parm - 1 && averageRating < parm) {
      return HalfStar;
    }
    if (averageRating < parm) {
      return BlankStar;
    }
  }
  const submitReview = async () => {
    if (shareReviewStars == 0) {
      setReviewError((state) => {
        return { ...state, starError: true };
      });
    } else {
      setReviewError((state) => {
        return { ...state, starError: false };
      });
    }
    if (reviewMessage == "") {
      setReviewError((state) => {
        return { ...state, messageError: true };
      });
    } else {
      setReviewError((state) => {
        return { ...state, messageError: false };
      });
    }

    setReviewResult(false);
    if (reviewMessage != "" && shareReviewStars != 0) {
      let resp = await addReview(shareReviewStars, reviewMessage);
      if (resp.response == "success") {
        let datatosend = {
          alert: {
            type: "green",
            message: "Review posted successfully",
            duration: 5000,
          },
        };
        reviewModal.current.click();
        setShareReviewStars(0);
        sendDataToApp(datatosend);

        setReviewMessage("");
      }
      if (resp.response == "error") {
        setReviewResult(true);
        let datatosend = {
          alert: {
            type: "red",
            message: "Something went wrong while posting review",
            duration: 5000,
          },
        };
        reviewModal.current.click();
        setShareReviewStars(0);
        sendDataToApp(datatosend);

        setReviewMessage("");
      }
    }
  };
  // componentdidmount
  useEffect(() => {
    window.addEventListener("message", (message) => {
      let data = JSON.parse(message.data);
      if (data.user) {
        setUserData(data.user);
      }
    });
    document.addEventListener("message", (message) => {
      let data = JSON.parse(message.data);
      if (data.user) {
        setUserData(data.user);
      }
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div
        className="simpleflex px-3  py-2"
        onClick={() => {
          navigate("/");
        }}
        style={{ position: "absolute", backgroundColor: "rgb(240,240,240)" }}
      >
        <span>&#8592;</span>
      </div>
      <div className="container">
        {pageStage != "loading" && allReviews.length > 0 && (
          <div className="phase">
            <div
              className="simpleflex py-4"
              style={{ flexDirection: "column" }}
            >
              <span style={{ fontSize: 23, fontWeight: "600" }}>
                {averageRating}
              </span>
              <div className="star-wrapper my-3">
                <img
                  src={setStars(1)}
                  alt="Review Star"
                  style={{ marginRight: 3 }}
                />
                <img
                  src={setStars(2)}
                  alt="Review Star"
                  style={{ marginRight: 3 }}
                />
                <img
                  src={setStars(3)}
                  alt="Review Star"
                  style={{ marginRight: 3 }}
                />
                <img
                  src={setStars(4)}
                  alt="Review Star"
                  style={{ marginRight: 3 }}
                />
                <img
                  src={setStars(5)}
                  alt="Review Star"
                  style={{ marginRight: 3 }}
                />
              </div>
              <span className="text-muted">
                Based on {allReviews.length} reviews
              </span>
            </div>
            <div className="rating-bar-wrapper">
              <div className="d-flex">
                <div
                  className="text-center text-muted"
                  style={{ flex: 2, fontSize: 14 }}
                >
                  Excellent
                </div>
                <div style={{ flex: 3 }} className="simpleflex">
                  <div
                    className="bar-wrapper "
                    style={{
                      backgroundColor: "lightgrey",
                      height: 10,
                      width: "100%",
                      borderRadius: 3,
                    }}
                  >
                    <div
                      style={{
                        width: `${fiveStarBar}%`,
                        height: 10,
                        backgroundColor: "green",
                        borderRadius: 3,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div
                  className="text-center text-muted"
                  style={{ flex: 2, fontSize: 14 }}
                >
                  Good
                </div>
                <div style={{ flex: 3 }} className="simpleflex">
                  <div
                    className="bar-wrapper "
                    style={{
                      backgroundColor: "lightgrey",
                      height: 10,
                      width: "100%",
                      borderRadius: 3,
                    }}
                  >
                    <div
                      style={{
                        width: `${fourStarBar}%`,
                        height: 10,
                        backgroundColor: "#a8e4a0",
                        borderRadius: 3,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div
                  className="text-center text-muted"
                  style={{ flex: 2, fontSize: 14 }}
                >
                  Average
                </div>
                <div style={{ flex: 3 }} className="simpleflex">
                  <div
                    className="bar-wrapper "
                    style={{
                      backgroundColor: "lightgrey",
                      height: 10,
                      width: "100%",
                      borderRadius: 3,
                    }}
                  >
                    <div
                      style={{
                        width: `${threeStarBar}%`,
                        height: 10,
                        backgroundColor: "yellow",
                        borderRadius: 3,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div
                  className="text-center text-muted"
                  style={{ flex: 2, fontSize: 14 }}
                >
                  Below Average
                </div>
                <div style={{ flex: 3 }} className="simpleflex">
                  <div
                    className="bar-wrapper "
                    style={{
                      backgroundColor: "lightgrey",
                      height: 10,
                      width: "100%",
                      borderRadius: 3,
                    }}
                  >
                    <div
                      className=""
                      style={{
                        width: `${twoStarBar}%`,
                        height: 10,
                        backgroundColor: "orange",
                        borderRadius: 3,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div
                  className="text-center text-muted"
                  style={{ flex: 2, fontSize: 14 }}
                >
                  Poor
                </div>
                <div style={{ flex: 3 }} className="simpleflex">
                  <div
                    className="bar-wrapper "
                    style={{
                      backgroundColor: "lightgrey",
                      height: 10,
                      width: "100%",
                      borderRadius: 3,
                    }}
                  >
                    <div
                      style={{
                        width: `${oneStarBar}%`,
                        height: 10,
                        backgroundColor: "red",
                        borderRadius: 3,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="review-wrapper mt-3">
              <div className="reviewcont-header simpleflex py-1 mb-1 border">
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#reviewModal"
                >
                  Write a Review
                </span>
              </div>
              <div
                className="modal fade"
                id="reviewModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      {userdata.userid && (
                        <div>
                          <div className="d-flex justify-content-between">
                            <span>Share your opinion about our sevices</span>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              ref={reviewModal}
                            ></button>
                          </div>
                          <div className="stars-wrapper simpleflex my-3">
                            <img
                              className="mx-1"
                              src={
                                shareReviewStars >= 1 ? StarBig : BlankStarBig
                              }
                              alt="star rating"
                              onClick={() => {
                                setShareReviewStars(1);
                              }}
                            />
                            <img
                              className="mx-1"
                              src={
                                shareReviewStars >= 2 ? StarBig : BlankStarBig
                              }
                              alt="star rating"
                              onClick={() => {
                                setShareReviewStars(2);
                              }}
                            />
                            <img
                              className="mx-1"
                              src={
                                shareReviewStars >= 3 ? StarBig : BlankStarBig
                              }
                              alt="star rating"
                              onClick={() => {
                                setShareReviewStars(3);
                              }}
                            />
                            <img
                              className="mx-1"
                              src={
                                shareReviewStars >= 4 ? StarBig : BlankStarBig
                              }
                              alt="star rating"
                              onClick={() => {
                                setShareReviewStars(4);
                              }}
                            />
                            <img
                              className="mx-1"
                              src={
                                shareReviewStars >= 5 ? StarBig : BlankStarBig
                              }
                              alt="star rating"
                              onClick={() => {
                                setShareReviewStars(5);
                              }}
                            />
                          </div>
                          <div className="simpleflex pt-3">
                            <div
                              className="inp-wrapper"
                              style={{ position: "relative", width: "80%" }}
                            >
                              <label
                                htmlFor="review_name"
                                className="px-1"
                                style={{
                                  position: "absolute",
                                  top: -12,
                                  left: 8,
                                  backgroundColor: "white",
                                  color: "grey",
                                }}
                              >
                                Message
                              </label>
                              <textarea
                                name="review_name"
                                className="form-control"
                                style={{
                                  borderColor: "grey",
                                  borderWidth: 1,
                                  width: "100%",
                                  borderStyle: "solid",
                                  height: 70,
                                  paddingTop: 10,
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                }}
                                value={reviewMessage}
                                onChange={(e) => {
                                  setReviewMessage(e.target.value);
                                }}
                                placeholder="write something about us"
                              />
                            </div>
                          </div>
                          <div
                            className="simpleflex"
                            style={{ flexDirection: "column" }}
                          >
                            <div
                              className="text center d-flex"
                              style={{ flexDirection: "column", height: 50 }}
                            >
                              {reviewError.starError && (
                                <span style={{ fontSize: 14, color: "red" }}>
                                  * Please select number of stars you want to
                                  give
                                </span>
                              )}
                              {reviewError.messageError && (
                                <span style={{ fontSize: 14, color: "red" }}>
                                  * Message field cannot be empty
                                </span>
                              )}
                            </div>
                            <button
                              type="button"
                              className="btn text-light mt-3 w-50"
                              style={{ backgroundColor: "red" }}
                              onClick={() => {
                                submitReview();
                              }}
                            >
                              {addingReviewLoading ? (
                                <Spinner borderColor="#ffffff" size="24px" />
                              ) : (
                                "Post"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                      {!userdata.userid && (
                        <div
                          className="simpleflex"
                          style={{ flexDirection: "column" }}
                        >
                          {" "}
                          <div className="d-flex justify-content-end w-100">
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>{" "}
                          <span className="mt-3" style={{ fontSize: 14 }}>
                            You need to login first
                          </span>{" "}
                          <button
                            className="btn my-3 w-50"
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              fontSize: 14,
                            }}
                            onClick={() => {
                              let datatosend = { login: "navigate to login" };
                              sendDataToApp(datatosend);
                            }}
                          >
                            Login
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {allReviews.map((item, index) => {
                return <UserReview data={item} key={index} />;
              })}
            </div>
          </div>
        )}
        {!hreviewloading && allReviews.length == 0 && (
          <div className="simpleflex py-3" style={{ height: "100vh" }}>
            <span className="text-muted">No reviews to show</span>
          </div>
        )}
        {pageStage == "loading" && (
          <div
            className="py-3 simpleflex"
            style={{ flexDirection: "column", height: "100vh" }}
          >
            <Spinner borderColor="#000000" size="28px" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
