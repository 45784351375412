import React, { useRef, useState } from "react";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import Offer from "../../assets/washprogram/offers.png";
import Next from "../../assets/washprogram/next.png";
import Prev from "../../assets/washprogram/previous.png";
import Spinner from "../Spinner";

export default function WashprogramAccordian(props) {
  const context = useContext(AppContext);
  const {
    washprogram,
    allwashPrograms,
    setWashProgram,
    washprogramLoading,
    washprogramError,
    offerValid,
    activeOffer,
  } = context;
  const [wpNum, setWPnum] = useState(1);
  const [nxtBtn, setNxtBtn] = useState(false);
  const [prvBtn, setPrvBtn] = useState(true);
  const washprogram_btn = useRef();
  const washprogram_body = useRef();

  const min = 1;
  const max = allwashPrograms.length;

  function nextProgram() {
    let num = wpNum;
    if (wpNum < max) {
      num++;
      setWPnum((state) => {
        return state + 1;
      });
    }
    if (num === max) {
      setNxtBtn(true);
    } else {
      if (nxtBtn) {
        setNxtBtn(false);
      }
    }
    if (num === min) {
      setPrvBtn(true);
    } else {
      if (prvBtn) {
        setPrvBtn(false);
      }
    }
  }
  function prevProgram() {
    let num = wpNum;
    if (wpNum > min) {
      num--;
      setWPnum((state) => {
        return state - 1;
      });
    }
    if (num === min) {
      setPrvBtn(true);
    } else {
      if (prvBtn) {
        setPrvBtn(false);
      }
    }
    if (num === max) {
      setNxtBtn(true);
    } else {
      if (nxtBtn) {
        setNxtBtn(false);
      }
    }
  }
  function proceedFunc(program) {
    setWashProgram(program);

    washprogram_btn.current.classList.add("collapsed");
    washprogram_btn.current.setAttribute("aria-expanded", "false");
    washprogram_body.current.classList.remove("show");
  }
  return (
    <div className="accordion-item wash-program-accordion">
      <h2 className="accordion-header" id="headingOne">
        <button
          className="accordion-button collapsed "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="false"
          aria-controls="collapseOne"
          ref={washprogram_btn}
          style={{ fontSize: 14 }}
        >
          Wash Program : {"  "}
          <span style={{ fontWeight: "600", marginLeft: 3, fontSize: 14 }}>
            {washprogram ? washprogram : ""}
          </span>
          <span style={{ marginLeft: 3, fontSize: 14 }}>
            {" "}
            {washprogram ? "" : "Select your wash program"}
          </span>
        </button>
      </h2>
      <div
        id="collapseOne"
        className="accordion-collapse collapse"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
        ref={washprogram_body}
      >
        <div
          className="accordion-body"
          style={{ padding: 0, paddingTop: 15, paddingBottom: 15 }}
        >
          <div className="washprogram-body">
            <div
              className="washprogram-container"
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                position: "relative",
                display: "flex",
                flexFlow: "row",
                justifyContent: "center",
              }}
            >
              {allwashPrograms.length > 1 && (
                <div className="controls-wrapper">
                  <div
                    className="prev-control-cont d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: prvBtn
                        ? "rgba(0,0,0,0.2)"
                        : "rgba(0,0,0,0.8)",
                    }}
                    onClick={prevProgram}
                  >
                    <img src={Prev} alt="Change wash program" />
                  </div>
                  <div
                    className="next-control-cont d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: nxtBtn
                        ? "rgba(0,0,0,0.2)"
                        : "rgba(0,0,0,0.8)",
                    }}
                    onClick={nextProgram}
                  >
                    <img src={Next} alt="Change wash program" />
                  </div>
                </div>
              )}
              {washprogramLoading && (
                <div
                  className="wash-program border simpleflex"
                  style={{ minHeight: 200, flexDirection: "column" }}
                >
                  <Spinner borderColor="#000000" size="28px" />
                </div>
              )}
              {washprogramError && (
                <div
                  className="wash-program border simpleflex"
                  style={{ minHeight: 200, flexDirection: "column" }}
                >
                  <span
                    className="my-1 text-center px-3"
                    style={{ fontSize: 14 }}
                  >
                    Sorry, we couldn't fetch washprograms from server, this is
                    development error, kindly report it on{" "}
                    <strong>complaint@shortwash.com</strong>
                  </span>
                </div>
              )}
              {allwashPrograms.map((item, index) => {
                let pdesc = item.program_description.split("$");
                if (index === wpNum - 1) {
                  return (
                    <div
                      className={`wash-program border ${
                        washprogram === item.program_name
                          ? "border-primary"
                          : ""
                      }`}
                      style={{
                        margin: 5,
                        width: "50%",
                        position: "relative",
                        boxShadow:
                          washprogram === item.program_name
                            ? "0 0 5px #0d6efd"
                            : "0 0 5px #000000",
                      }}
                      key={item.program_name}
                    >
                      <div
                        className="program-header text-center py-1"
                        style={{ minHeight: 56 }}
                      >
                        <span style={{ fontSize: 16, fontWeight: "500" }}>
                          {item.program_name}
                        </span>
                      </div>
                      
                      <div
                        className="program-details d-flex justify-content-center align-items-center py-1"
                        style={{ marginBottom: 105 }}
                      >
                        <ul
                          className="program-details-ul "
                          style={{ padding: 5, textAlign: "center" }}
                        >
                          <li
                            className="program-details-li"
                            style={{ fontSize: 14 }}
                          >
                            {pdesc[0]}
                          </li>
                        </ul>
                      </div>
                      <div
                        className="offer-rate-btn-wrapper"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          width: "100%",
                        }}
                      >
                        {offerValid && (
                          <div className="program-offers-wrapper d-flex justify-content-center align-items-center">
                            <img
                              src={Offer}
                              alt="Offer logo"
                              style={{ width: 14, height: 14 }}
                            />
                            <span
                              style={{
                                color: "red",
                                marginLeft: 5,
                                fontSize: 13,
                              }}
                            >
                              OFFER APPLIED
                            </span>
                          </div>
                        )}
                        <div
                          className="program-rate-wrapper d-flex justify-content-center align-items-center position-relative"
                          style={{ flexFlow: "column" }}
                        >
                          {offerValid && (
                            <div>
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: 14,
                                }}
                              >
                                &#8377;{" "}
                                {(item.program_rate *
                                  (100 - activeOffer.offer_rate)) /
                                  100}
                                /{item.unit}
                              </span>
                              <span
                                className="text-muted"
                                style={{ margin: 3, fontSize: 14 }}
                              >
                                &#8377;{" "}
                                <del>
                                  {item.program_rate}/{item.unit}
                                </del>
                              </span>
                            </div>
                          )}
                          {!offerValid && (
                            <div>
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: 14,
                                }}
                              >
                                &#8377; {item.program_rate} per {item.unit}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="program-btn-wrapper d-flex justify-content-center align-items-center my-2 mb-4 w-100">
                          <button
                            type="button"
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              fontSize: 14,
                              paddingTop: 3,
                              paddingBottom: 3,
                            }}
                            className="btn btn-danger"
                            onClick={() => {
                              proceedFunc(item.program_name);
                            }}
                            disabled={
                              washprogram === item.program_name ? true : false
                            }
                          >
                            {washprogram === item.program_name
                              ? "Selected"
                              : "Select"}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return "";
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
