import React from "react";
import { useContext, useState, useRef } from "react";
import AppContext from "../../context/AppContext";
import Spinner from "../Spinner";

export default function NewAddressSection() {
  const context = useContext(AppContext);
  const { addAddress, sendDataToApp } = context;

  const [newAddress, setNewAddress] = useState({
    name: "",
    mobile: "",
    address1: "",
    address2: "",
    landmark: "",
    pincode: "122052",
    city: "Gurugram",
    state: "Haryana",
  });
  const [savingaddress, setSavingAddress] = useState(false);

  const cancel_address = useRef();

  const [addressErrors, setAddressErrors] = useState({
    name: false,
    mobile: false,
    address1: false,
    address2: false,
    landmark: false,
    pincode: false,
    city: false,
    state: false,
  });

  // add new address
  function naddChange(e) {
    if (e.target.id == "mobile") {
      if (e.target.value.length <= 10) {
        if (
          !e.target.value.includes(".") ||
          !e.target.value.includes("-") ||
          !e.target.value.includes("+")
        ) {
          setNewAddress((state) => {
            return { ...state, [e.target.id]: e.target.value };
          });
        }
      }
    } else {
      setNewAddress((state) => {
        return { ...state, [e.target.id]: e.target.value };
      });
    }
  }
  const naddsubmit = async () => {
    // resetting errors
    setAddressErrors({
      name: false,
      mobile: false,
      address1: false,
      address2: false,
      landmark: false,
      pincode: false,
      city: false,
      state: false,
    });

    let nameErr = false;
    let mobileErr = false;
    let address1Err = false;
    let address2Err = false;
    let landmarkErr = false;
    let pincodeErr = false;
    let cityErr = false;
    let stateErr = false;

    // validations
    if (newAddress.name == "" || newAddress.name.length < 3) {
      setAddressErrors(function (state) {
        return { ...state, name: true };
      });
      nameErr = true;
    } else {
      nameErr = false;
    }
    if (
      newAddress.mobile == "" ||
      newAddress.mobile.length < 10 ||
      newAddress.mobile.length > 10
    ) {
      mobileErr = true;
      setAddressErrors(function (state) {
        return { ...state, mobile: true };
      });
    } else {
      mobileErr = false;
    }
    if (newAddress.address1 == "" || newAddress.address1.length < 3) {
      address1Err = true;
      setAddressErrors(function (state) {
        return { ...state, address1: true };
      });
    } else {
      address1Err = false;
    }
    if (newAddress.address2 == "" || newAddress.address2.length < 3) {
      address2Err = true;
      setAddressErrors(function (state) {
        return { ...state, address2: true };
      });
    } else {
      address2Err = false;
    }
    if (newAddress.landmark == "" || newAddress.landmark.length < 3) {
      landmarkErr = true;
      setAddressErrors(function (state) {
        return { ...state, landmark: true };
      });
    } else {
      landmarkErr = false;
    }
    if (
      newAddress.pincode == "" ||
      newAddress.pincode.length < 6 ||
      newAddress.pincode.length > 6
    ) {
      pincodeErr = true;
      setAddressErrors(function (state) {
        return { ...state, pincode: true };
      });
    } else {
      pincodeErr = false;
    }
    if (newAddress.city == "" || newAddress.city.length < 3) {
      cityErr = true;
      setAddressErrors(function (state) {
        return { ...state, city: true };
      });
    } else {
      cityErr = false;
    }
    if (newAddress.state == "" || newAddress.state.length < 3) {
      stateErr = true;
      setAddressErrors(function (val) {
        return { ...val, state: true };
      });
    } else {
      stateErr = false;
    }

    if (
      !nameErr &&
      !mobileErr &&
      !address1Err &&
      !address2Err &&
      !landmarkErr &&
      !pincodeErr &&
      !cityErr &&
      !stateErr
    ) {
      setSavingAddress(true);
      let res = await addAddress(newAddress);
      if (res.response == "success") {
        let datatosend = {
          alert: {
            type: "green",
            message: "Address added successfully",
            duration: 5000,
          },
        };
        setNewAddress({
          name: "",
          mobile: "",
          address1: "",
          address2: "",
          landmark: "",
          pincode: "122052",
          city: "Gurugram",
          state: "Haryana",
        });
        sendDataToApp(datatosend);
        cancel_address.current.click();
      }
      if (res.response == "failed") {
        let datatosend = {
          alert: {
            type: "red",
            message: "Something went wrong, try again later",
            duration: 5000,
          },
        };
        sendDataToApp(datatosend);
      }
      setSavingAddress(false);
    }
  };
  return (
    <div className="stage-addnewaddress-wrapper simpleflex">
      <button
        type="button"
        className="btn btn-outline-primary w-100"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        style={{ fontSize: 14 }}
      >
        + Add new address
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="exampleModalLabel"
                style={{ fontSize: 23 }}
              >
                Add new address
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="area-warning-wrapper px-2 py-2">
                <div
                  className=" px-2 py-2"
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#f5f5dc ",
                    display: "flex",
                  }}
                >
                  <div className="simpleflex" style={{ width: 50 }}>
                    <img
                      src={require("../../assets/warning.png")}
                      alt="Warning"
                      style={{ height: 25, width: 25 }}
                    />
                  </div>
                  <span
                    style={{
                      color: "black",
                      flex: 1,
                      textAlign: "left",
                    }}
                  >
                    Currently operating in <strong>Sector-1 & Vatika India Next</strong>{" "}
                    only, if you live outside our serviceable zone, please wait
                    & we will serve you soon!
                  </span>
                </div>
              </div>
              <div className="form-wrapper">
                <div
                  className="input-wrapper my-3  py-2"
                  style={{
                    position: "relative",
                    marginLeft: "3%",
                  }}
                >
                  <label
                    htmlFor="name"
                    className="px-1 add-address-label"
                    style={{
                      position: "absolute",
                      top: -3,
                      left: 12,
                      backgroundColor: "white",
                      fontSize: 14,
                      color: addressErrors.name ? "red" : "grey",
                    }}
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="py-2 px-2 add-address-inp form-control"
                    style={{
                      width: "95%",
                      fontSize: 14,
                      borderColor: addressErrors.name ? "red" : "grey",
                    }}
                    placeholder="Type your full name"
                    value={newAddress.name}
                    id="name"
                    onChange={naddChange}
                  />
                  <span
                    style={{
                      color: "red",
                      paddingTop: 3,
                      fontSize: 14,
                      display: addressErrors.name ? "flex" : "none",
                    }}
                  >
                    Name value is invalid!
                  </span>
                </div>
                <div
                  className="input-wrapper my-3  py-2"
                  style={{
                    position: "relative",
                    marginLeft: "3%",
                  }}
                >
                  <label
                    htmlFor="name"
                    className="px-1 add-address-label"
                    style={{
                      position: "absolute",
                      top: -3,
                      left: 12,
                      backgroundColor: "white",
                      fontSize: 14,
                      color: addressErrors.mobile ? "red" : "grey",
                    }}
                  >
                    Mobile
                  </label>
                  <input
                    type="number"
                    name="name"
                    className="py-2 px-2 add-address-inp form-control"
                    style={{
                      width: "95%",
                      fontSize: 14,
                      borderColor: addressErrors.mobile ? "red" : "grey",
                    }}
                    placeholder="Ex - 9876543210"
                    value={newAddress.mobile}
                    id="mobile"
                    onChange={naddChange}
                  />
                  <span
                    style={{
                      color: "red",
                      paddingTop: 3,
                      fontSize: 14,
                      display: addressErrors.mobile ? "flex" : "none",
                    }}
                  >
                    Please enter a valid mobile number without any country code
                    !
                  </span>
                </div>
                <div
                  className="input-wrapper my-3  py-2"
                  style={{
                    position: "relative",
                    marginLeft: "3%",
                  }}
                >
                  <label
                    htmlFor="name"
                    className="px-1 add-address-label"
                    style={{
                      position: "absolute",
                      top: -3,
                      left: 12,
                      backgroundColor: "white",
                      fontSize: 14,
                      color: addressErrors.address1 ? "red" : "grey",
                    }}
                  >
                    Address 1
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="py-2 px-2 add-address-inp form-control"
                    style={{
                      width: "95%",
                      fontSize: 14,
                      borderColor: addressErrors.address1 ? "red" : "grey",
                    }}
                    placeholder="House No./ Flat No. / Tower No."
                    value={newAddress.address1}
                    id="address1"
                    onChange={naddChange}
                  />
                  <span
                    style={{
                      color: "red",
                      paddingTop: 3,
                      fontSize: 14,
                      display: addressErrors.address1 ? "flex" : "none",
                    }}
                  >
                    Address 1 value is invalid!
                  </span>
                </div>
                <div
                  className="input-wrapper my-3  py-2"
                  style={{
                    position: "relative",
                    marginLeft: "3%",
                  }}
                >
                  <label
                    htmlFor="name"
                    className="px-1 add-address-label"
                    style={{
                      position: "absolute",
                      top: -3,
                      left: 12,
                      backgroundColor: "white",
                      fontSize: 14,
                      color: addressErrors.address2 ? "red" : "grey",
                    }}
                  >
                    Address 2
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="py-2 px-2 add-address-inp form-control"
                    style={{
                      width: "95%",
                      fontSize: 14,
                      borderColor: addressErrors.address2 ? "red" : "grey",
                    }}
                    placeholder="Society Name, Sector Name, Village Name"
                    value={newAddress.address2}
                    id="address2"
                    onChange={naddChange}
                  />
                  <span
                    style={{
                      color: "red",
                      paddingTop: 3,
                      fontSize: 14,
                      display: addressErrors.address2 ? "flex" : "none",
                    }}
                  >
                    Address 2 value is invalid!
                  </span>
                </div>
                <div
                  className="input-wrapper my-3  py-2"
                  style={{
                    position: "relative",
                    marginLeft: "3%",
                  }}
                >
                  <label
                    htmlFor="name"
                    className="px-1 add-address-label"
                    style={{
                      position: "absolute",
                      top: -3,
                      left: 12,
                      backgroundColor: "white",
                      fontSize: 14,
                      color: addressErrors.landmark ? "red" : "grey",
                    }}
                  >
                    Landmark
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="py-2 px-2 add-address-inp form-control"
                    style={{
                      width: "95%",
                      fontSize: 14,
                      borderColor: addressErrors.landmark ? "red" : "grey",
                    }}
                    placeholder="Famous place near you"
                    value={newAddress.landmark}
                    id="landmark"
                    onChange={naddChange}
                  />
                  <span
                    style={{
                      color: "red",
                      paddingTop: 3,
                      fontSize: 14,
                      display: addressErrors.landmark ? "flex" : "none",
                    }}
                  >
                    Landmark value is invalid!
                  </span>
                </div>
                <div className="simpleflex">
                  <span className="text-muted small">
                    You can't change pincode, city & state
                  </span>
                </div>
                <div
                  className="input-wrapper my-3  py-2"
                  style={{
                    position: "relative",
                    marginLeft: "3%",
                  }}
                >
                  <label
                    htmlFor="name"
                    className="px-1 add-address-label"
                    style={{
                      position: "absolute",
                      top: -3,
                      left: 12,
                      backgroundColor: "white",
                      fontSize: 14,
                      color: "grey",
                    }}
                  >
                    Pincode
                  </label>
                  <input
                    type="number"
                    name="name"
                    className="py-2 px-2 add-address-inp form-control"
                    style={{ width: "95%", fontSize: 14 }}
                    placeholder="Your pincode"
                    value={newAddress.pincode}
                    id="pincode"
                    readOnly
                    disabled
                  />
                  <span
                    style={{
                      color: "red",
                      paddingTop: 3,
                      fontSize: 14,
                      display: addressErrors.pincode ? "flex" : "none",
                    }}
                  >
                    Pincode value is invalid!
                  </span>
                </div>
                <div
                  className="input-wrapper my-3  py-2"
                  style={{
                    position: "relative",
                    marginLeft: "3%",
                  }}
                >
                  <label
                    htmlFor="name"
                    className="px-1 add-address-label"
                    style={{
                      position: "absolute",
                      top: -3,
                      left: 12,
                      backgroundColor: "white",
                      fontSize: 14,
                      color: "grey",
                    }}
                  >
                    City
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="py-2 px-2 add-address-inp form-control"
                    style={{ width: "95%", fontSize: 14 }}
                    placeholder="Your city"
                    value={newAddress.city}
                    id="city"
                    readOnly
                    disabled
                  />
                  <span
                    style={{
                      color: "red",
                      paddingTop: 3,
                      fontSize: 14,
                      display: addressErrors.city ? "flex" : "none",
                    }}
                  >
                    City value is invalid!
                  </span>
                </div>
                <div
                  className="input-wrapper my-3  py-2"
                  style={{
                    position: "relative",
                    marginLeft: "3%",
                  }}
                >
                  <label
                    htmlFor="name"
                    className="px-1 add-address-label"
                    style={{
                      position: "absolute",
                      top: -3,
                      left: 12,
                      backgroundColor: "white",
                      fontSize: 14,
                      color: "grey",
                    }}
                  >
                    State
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="py-2 px-2 add-address-inp form-control"
                    style={{ width: "95%", fontSize: 14 }}
                    placeholder="Your state"
                    value={newAddress.state}
                    id="state"
                    readOnly
                    disabled
                  />
                  <span
                    style={{
                      color: "red",
                      paddingTop: 3,
                      fontSize: 14,
                      display: addressErrors.state ? "flex" : "none",
                    }}
                  >
                    State value is invalid!
                  </span>
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ flexDirection: "column" }}
              >
                <button
                  type="button"
                  className="btn my-2"
                  onClick={naddsubmit}
                  style={{
                    fontSize: 14,
                    width: "95%",
                    backgroundColor: "red",
                    color: "white",
                  }}
                >
                  {savingaddress ? (
                    <Spinner borderColor="#ffffff" size="24px" />
                  ) : (
                    "Save changes"
                  )}
                </button>
                <button
                  type="button"
                  className="btn"
                  data-bs-dismiss="modal"
                  ref={cancel_address}
                  style={{
                    fontSize: 14,
                    width: "95%",
                    borderColor: "black",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
