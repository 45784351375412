import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";
import Spinner from "./Spinner";

export default function Overview(props) {
  const context = useContext(AppContext);
  const {
    washprogram,
    timeslot,
    pickupAdd,
    offerValid,
    activeOffer,
    setUserData,
    allwashPrograms,
    allAddress,
    guestMode,
    guestAddress,
    sendDataToApp,
    activeWashProgram,
    setActiveWashProgram,
  } = context;
  const [selectedAdd, setSelectedAdd] = useState({});

  const navigate = useNavigate();
  useEffect(() => {
    allwashPrograms.filter((program) => {
      if (program.program_name === washprogram) {
        setActiveWashProgram(program);
      }
    });
  }, [washprogram]);
  useEffect(() => {
    allAddress.filter((address) => {
      if (address.addressid == pickupAdd) {
        setSelectedAdd(address);
      }
    });
  }, [allAddress]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!washprogram || !timeslot || !(pickupAdd || guestMode)) {
      window.addEventListener("message", (message) => {
        let data = JSON.parse(message.data);
        if (data.user) {
          setUserData(data.user);
        }
      });
      document.addEventListener("message", (message) => {
        let data = JSON.parse(message.data);
        if (data.user) {
          setUserData(data.user);
        }
      });
      setTimeout(() => {
        let datatosend = {
          alert: {
            type: "red",
            message: "Please select wash program again",
            duration: 5000,
          },
        };
        sendDataToApp(datatosend);
        navigate("/home");
      }, 2000);
    }
  }, []);
  useEffect(() => {
    if (!washprogram || !timeslot || !(pickupAdd || guestMode)) {
      setTimeout(() => {
        let datatosend = {
          alert: {
            type: "red",
            message: "Please select wash program again",
            duration: 5000,
          },
        };
        sendDataToApp(datatosend);
        navigate("/home");
      }, 2000);
    }
  }, [washprogram, timeslot, pickupAdd, guestMode]);
  return (
    <React.Fragment>
      {washprogram && timeslot && (pickupAdd || guestMode) && (
        <div>
          <div className="text-center py-4">
            <span style={{ fontSize: 23 }} className="px-3">
              Overview
            </span>
          </div>
          <div className="overview-wrapper px-2">
            <div className="overview-field d-flex my-3">
              <div
                className="simpleflex text-muted"
                style={{ flex: 1, fontSize: 14 }}
              >
                <span>Wash program - </span>
              </div>
              <div style={{ flex: 1 }}>
                <span style={{ fontWeight: "500", fontSize: 14 }}>
                  {washprogram}
                </span>
              </div>
            </div>

            <div className="overview-field d-flex my-3">
              <div
                className="simpleflex text-muted"
                style={{ flex: 1, fontSize: 14 }}
              >
                <span>Pickup time slot - </span>
              </div>
              <div style={{ flex: 1 }}>
                <span style={{ fontWeight: "500", fontSize: 14 }}>
                  {timeslot}
                </span>
              </div>
            </div>
            <div className="overview-field d-flex my-3">
              <div
                className="simpleflex text-muted"
                style={{ flex: 1, fontSize: 14 }}
              >
                <span>Pickup & Delivery address - </span>
              </div>
              <div style={{ flex: 1 }}>
                <span style={{ fontWeight: "500", fontSize: 14 }}>
                  {pickupAdd
                    ? `${selectedAdd.name}, ${selectedAdd.address1}, ${selectedAdd.address2}, ${selectedAdd.landmark}, ${selectedAdd.city}, ${selectedAdd.state} - ${selectedAdd.pincode}, ${selectedAdd.mobile}`
                    : ""}
                  {guestMode
                    ? `${guestAddress.name}, ${guestAddress.address1}, ${guestAddress.address2}, ${guestAddress.landmark}, ${guestAddress.city}, ${guestAddress.state} - ${guestAddress.pincode}, ${guestAddress.mobile}`
                    : ""}
                </span>
              </div>
            </div>
            {offerValid && (
              <div className="overview-field d-flex my-3">
                <div
                  className="simpleflex text-muted"
                  style={{ flex: 1, fontSize: 14 }}
                >
                  <span>Offers/Discount - </span>
                </div>
                <div
                  className="d-flex"
                  style={{ flex: 1, flexDirection: "column" }}
                >
                  <span style={{ fontSize: 14 }}>
                    {activeOffer.offer_rate}% off
                  </span>
                </div>
              </div>
            )}
            <div className="overview-field d-flex my-3">
              <div
                className="simpleflex text-muted"
                style={{ flex: 1, fontSize: 14 }}
              >
                <span>Wash program rate - </span>
              </div>
              <div
                className="d-flex"
                style={{ flex: 1, flexDirection: "column" }}
              >
                <span style={{ fontSize: 14 }}>
                  &#8377;{activeWashProgram.program_rate}/{activeWashProgram.unit}
                </span>
              </div>
            </div>
            {offerValid && (
              <div className="overview-field d-flex my-3">
                <div
                  className="simpleflex text-muted"
                  style={{ flex: 1, fontSize: 14 }}
                >
                  <span>Rate afer discount - </span>
                </div>
                <div
                  className="d-flex"
                  style={{ flex: 1, flexDirection: "column" }}
                >
                  <span style={{ fontSize: 14 }}>
                    &#8377;
                    {activeWashProgram.program_rate *
                      ((100 - activeOffer.offer_rate) / 100)}/{activeWashProgram.unit} (Offer applied)
                  </span>
                </div>
              </div>
            )}
            
            <div className="overview-field d-flex my-3 px-3">
              <div
                className="simpleflex text-muted"
                style={{ flex: 1, fontSize: 14 }}
              >
                <span className="text-center">
                  Clothes weight and total number of items will be calculated at
                  the time of order pickup{" "}
                </span>
              </div>
            </div>
            <div
              className="overfield d-flex my-3 px-3"
              style={{ flexDirection: "column" }}
            >
              <button
                className="btn text-light my-2"
                style={{ backgroundColor: "red", fontSize: 14 }}
                onClick={() => {
                  navigate("/confirm");
                }}
              >
                Place Order
              </button>
              <button
                className="btn my-2"
                style={{ borderColor: "black", fontSize: 14 }}
                onClick={() => {
                  navigate("/stages");
                }}
              >
                Change Wash Program/Timeslot/Address
              </button>
            </div>
          </div>
        </div>
      )}
      {(!washprogram || !timeslot || !(pickupAdd || guestMode)) && (
        <div className="simpleflex" style={{ height: "100vh" }}>
          <Spinner borderColor="#000000" size="28px" />
        </div>
      )}
    </React.Fragment>
  );
}
