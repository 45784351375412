import React, { useContext, useRef, useEffect } from "react";
import AppContext from "../context/AppContext";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import WashprogramAccordian from "./stagescomponents/WashprogramAccordian";
import TimeslotAccordian from "./stagescomponents/TimeslotAccordian";
import PickupaddressAccordian from "./stagescomponents/PickupaddressAccordian";

export default function Stages() {
  const context = useContext(AppContext);
  const {
    washprogram,
    timeslot,
    pickupAdd,
    setUserData,
    guestMode,
    fetchAddress,
    userdata,
  } = context;

  const [stageErros, setStageErrors] = useState({
    washprogramError: false,
    pickuptimeslotError: false,
    deliveryaddressError: false,
  });

  const confirm_btn = useRef();

  const navigate = useNavigate();

  // componentdidmount
  useEffect(() => {
    window.addEventListener("message", (message) => {
      let data = JSON.parse(message.data);
      if (data.user) {
        setUserData(data.user);
      }
    });
    document.addEventListener("message", (message) => {
      let data = JSON.parse(message.data);
      if (data.user) {
        setUserData(data.user);
      }
    });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchAddress();
  }, [userdata]);
  // go to overview
  function finalStage() {
    // validation
    if (washprogram === null) {
      setStageErrors((state) => {
        return { ...state, washprogramError: true };
      });
    } else {
      setStageErrors((state) => {
        return { ...state, washprogramError: false };
      });
    }
    if (timeslot === null) {
      setStageErrors((state) => {
        return { ...state, pickuptimeslotError: true };
      });
    } else {
      setStageErrors((state) => {
        return { ...state, pickuptimeslotError: false };
      });
    }
    if (pickupAdd === null && guestMode === false) {
      setStageErrors((state) => {
        return { ...state, deliveryaddressError: true };
      });
    } else {
      setStageErrors((state) => {
        return { ...state, deliveryaddressError: false };
      });
    }
    if (washprogram && timeslot && (pickupAdd || guestMode)) {
      navigate("/overview");
    }
  }
  return (
    <React.Fragment>
      <div className="container stages-wrapper">
      
        <div className="simpleflex text-center pb-3 ">
          <span className="text-muted">
            Do you know an average family person who washes clothes spends 7.5
            years of his lifespan just cleaning clothes?
          </span>
        </div>
        <div className="stages-wrapper">
          <div className="accordion" id="accordionExample">
            <WashprogramAccordian />

            <TimeslotAccordian />

            <PickupaddressAccordian />
          </div>
          <div className="button-wrapper my-4">
            <div
              className="d-flex text-center my-3"
              style={{
                flexDirection: "column",
              }}
            >
              {stageErros.washprogramError && (
                <span style={{ fontSize: 14, color: "red" }}>
                  * Please select wash program
                </span>
              )}
              {stageErros.pickuptimeslotError && (
                <span style={{ fontSize: 14, color: "red" }}>
                  * Please select a pickup timeslot
                </span>
              )}
              {stageErros.deliveryaddressError && (
                <span style={{ fontSize: 14, color: "red" }}>
                  * Please select a pickup & delivery address or checkout as
                  guest
                </span>
              )}
            </div>
            <button
              className="btn w-100 text-light"
              ref={confirm_btn}
              style={{ backgroundColor: "red", fontSize: 14 }}
              onClick={() => {
                finalStage();
              }}
            >
              Confirm Order
            </button>
            <button
              className="btn w-100 border-dark mt-3"
              style={{ backgroundColor: "white", fontSize: 14 }}
              onClick={() => {
                navigate("/");
              }}
            >
              Cancel Order
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
