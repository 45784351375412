import React, { useState } from "react";
import { useEffect } from "react";
import Context from "./AppContext";

export default function AppState(props) {
  const [washprogram, setWashProgram] = useState(null);
  const [timeslot, setTimeSlot] = useState("05:00 - 09:00 PM");
  const [pickupAdd, setPickupAdd] = useState(null);
  // let withofferuserid = "8cfebf62d92342fed00aa956f57287ad";
  // let withoutofferuserid = "e388c1c5df4933fa01f6da9f92595589";
  // let temp = {
  //   email: "abhiydv1844@gmail.com",
  //   mobile: "9599175745",
  //   userid: withoutofferuserid,
  //   loggedin: true,
  //   wallet: "0",
  //   name: "Abhishek",
  // };
  const tempData = {
    mobile: '9599175744',
    email: 'abhiydv1844@gmail.com',
    name: 'Abhishek',
    userid: "c8791e28d6d2e7f3adf9bd2b6100b186",
    wallet: 134695,
    loggedin : true
  }
  const [userdata, setUserData] = useState({
    loggedin : false
  });
  const [pageLoading, setPageLoading] = useState(false); 
  const [allAddress, setAllAddress] = useState([]);
  const [allwashPrograms, setAllWashPrograms] = useState([]);
  const [washprogramLoading, setWashprogramLoading] = useState(false);
  const [washprogramError, setWashProgramError] = useState(false);
  const [hreviewError, setHreviewError] = useState(false);
  const [offerTransaction, setOfferTransaction] = useState([]);
  const [offerValid, setOfferValid] = useState(false);
  const [activeOffer, setActiveOffer] = useState({ offer_rate: 0 });
  const [allReviews,setAllReviews] = useState([]);
  const [hreviewloading,sethreviewloading] = useState(null);
  const [addingReviewLoading,setAddingReviewLoading] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState({program_name : null});
  const [variations,setVariations] = useState([]);
  const [programRate, setProgramRate] = useState(0);
  const [detergent, setDetergent] = useState(null);
  const [conditioner, setConditioner] = useState(null);
  const [ironType, setIronType] = useState(null);
  const [orderStage, setOrderStage] = useState(0);


  const [activeWashProgram,setActiveWashProgram] = useState({program_rate : 0});
  let [signupFieldDetails, setSignupFieldDetails] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    referral: "",
    verification:""
  });
  let [signupErrorDetails, setSignupErrors] = useState({
    password: false,
    name: false,
    mobileLen: false,
    mobileExists: false,
    email: false,
    verification: false,
  });
  const [signupconfirmRes,setSignupConfirmRes] = useState(null);
  const [fpUser,setFpUser] = useState(null);
  const [windowSize,setWindowSize] = useState({size : {}});

  const showPageLoading = (val) => {
    setPageLoading(val);
  };

  async function sendDataToApp(data) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }
  const signupUser = async(data)=>{
    try {
      let response = await fetch("https://expressv2.shortwash.com/login/signup.php",{
      method : "POST",
      body : JSON.stringify(data)
    });
    let responseData = await response.json();
    return responseData;
    } catch (error) {
      logError(error,{data,cause:"signupUser function - appstate webview"})
    }
  }
  
  const getallproandrev = async () => {
  setWashprogramLoading(true);
  sethreviewloading(true);
    try {
    let url = "https://expressv2.shortwash.com/combinedata/proandrev.php";

    const response = await fetch(url, {
      method: "GET",
    });
    const responseData = await response.json();
    if (responseData.response === "success") {
      setAllWashPrograms(responseData.data.program);
      setAllReviews(responseData.data.reviews);
      setVariations(responseData.data.variations);
      setWashProgramError(false);
    }
    if (responseData.response === "error") {
      setWashProgramError(true);
      setHreviewError(true);
    }
  } catch (error) {
    logError(error,{cause:"getallproandrev function - appstate webview"})
  }
  setWashprogramLoading(false);
  sethreviewloading(false);

  };

  // address functions
  const addAddress = async (data) => {
    try {
      if (userdata.loggedin !== false) {
        if (userdata.userid) {
          let url = "https://expressv2.shortwash.com/address/address.php";
          let reqData = { ...data, userid: userdata.userid };
  
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reqData),
          });
  
          const responseData = await response.json();
          if (responseData.response === "success") {
            fetchAddress();
          }
          return responseData;
        }
      }
    } catch (error) {
      logError(error,{data,userdata,cause:"addAddress function - appstate webview"})
    }
  };
  const getAllAddress = async () => {
    try {
      if (userdata.loggedin !== false) {
        if (userdata.userid) {
          let url = "https://expressv2.shortwash.com/address/getaddress.php";
          let reqData = { userid: userdata.userid };
  
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reqData),
          });
          const responseData = await response.json();
          return responseData;
        }
      }else{
        setAllAddress([]);
      }
    } catch (error) {
      logError(error,{data:userdata,cause:"getalladdress function - appstate webview"})
    }
  };
  const fetchAddress = async () => {
    if (userdata.loggedin !== false) {
      showPageLoading(true);
      let reqData = await getAllAddress();
      if (reqData.response === "success") {
        setAllAddress(reqData.data);
      }
      if (reqData.response === "error") {
        setAllAddress([]);
      }
      setPageLoading(false);
    }else{
      setAllAddress([]);
    }
  };

  // user offers
  const getuserOffers = async () => {
    try {
      if (userdata.userid) {
        let url = "https://expressv2.shortwash.com/offers/getoffertransaction.php";
        let reqData = { userid: userdata.userid };
  
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reqData),
        });
  
        const responseData = await response.json();
        if (responseData.response === "success") {
          setOfferTransaction(responseData.data);
        }
        console.log('user present');
      }else{
        setOfferTransaction([]);
        setActiveOffer({offer_rate : 0});
        setOfferValid(false);
        console.log('user not present');
      }
    } catch (error) {
      logError(error,{userdata,cause:"getuseroffers function - appstate webview"})
    }
  };

  // reviews 
  const getAllReviews = async () => {
    try {
      sethreviewloading(true);
      let url = "https://expressv2.shortwash.com/reviews/getreviews.php";
      const response = await fetch(url, {
        method: "GET"
      });

      const responseData = await response.json();
      if (responseData.response === "success") {
        setAllReviews(responseData.data);
      }
      sethreviewloading(false);
    } catch (error) {
      logError(error,{cause:"getallreviews function - appstate webview"})
    }
  };
  const addReview = async(star,message)=>{
    try {
      setAddingReviewLoading(true);
    if (userdata.userid) {
      let data = {
        userid : userdata.userid,
        star,
        message
      }
      let url = "https://expressv2.shortwash.com/reviews/addreview.php";
      const response = await fetch(url,{
        method : 'POST',
        headers : {
          'Content-Type' : 'application/json'
        },
        body : JSON.stringify(data)
      });
      const responseData = await response.json();
      if (responseData.response ==='success') {
        await getAllReviews();
        setAddingReviewLoading(false);
        return responseData;
      }else{
        setAddingReviewLoading(false);
        return responseData;
      }
    }
    } catch (error) {
      logError(error,{data:{star,message,userdata},cause:"addreview function - appstate webview"})
    }
  }

  // order
  const addOrder = async()=>{
    try {
      if (userdata.userid && pickupAdd && timeslot && selectedProgram) {
        const url = "https://expressv2.shortwash.com/orders/addorder.php";
        let data = {
          userid : userdata.userid,
          addressid : pickupAdd,
          timeslot,
          program : selectedProgram.programid,
          programrate : selectedProgram.program_rate,
          detergent : detergent ? detergent : 'null',
          conditioner : conditioner ? conditioner : 'null',
          iron : ironType ? ironType : 'null'
        }
        if (offerValid) {
          let od_rate = activeWashProgram.program_rate * (100 - activeOffer.offer_rate)/100;
          data = {...data,offerid : activeOffer.offerid,offerrate : activeOffer.offer_rate,orderrate : od_rate}
        }else {
          data = {...data,orderrate : programRate};
        }
        const response = await fetch(url,{
          method : 'POST',
          headers : {
            'Content-Type' : "application/json"
          },
          body : JSON.stringify(data)
        })
        const responseData = await response.json();
        return responseData;
        // setOrderStatus(responseData);
      }else {
        return {response : 'error'};
        // setOrderStatus({response : 'error'});
      }
    } catch (error) {
      logError(error,{data:{userdata,pickupAdd,timeslot,selectedProgram},cause:"addOrder function - appstate webview"})
    }
  }
  const addOrderRequest = async()=>{
    try {
      if (userdata.userid) {
        const url = "https://expressv2.shortwash.com/orders/addorderrequest.php";
        let data = {
          userid : userdata.userid,
          orderstage : orderStage
        }
        const response = await fetch(url,{
          method : 'POST',
          headers : {
            'Content-Type' : "application/json"
          },
          body : JSON.stringify(data)
        })
        const responseData = await response.json();
        console.log(responseData);
      }else {
        console.log({response : 'error'});
      }
    } catch (error) {
      logError(error,{data:{userdata,pickupAdd,timeslot,selectedProgram},cause:"addOrderRequest function - appstate webview"})
    }
  }



  async function logError(error, data = null) {
    if (error.message != "Failed to fetch") {
      
      let jsonData = JSON.stringify({
        message: error.message,
        stack: error.stack,
        dependencies: { data },
      });
      const response = await fetch(
        "https://expressv2.shortwash.com/error/adderror.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({error : jsonData}),
        }
      );
      const responseData = await response.json();
      if (responseData.response == 'success') {
        let datatosend = {
          alert : {
            type : "red",
            message : `Error - ${error.name} has been posted to developers`,
            duration : 5000
          }
        }
        sendDataToApp(datatosend);
      }
    }
  }

  // updating address and user specific offers 
  useEffect(() => {
    getuserOffers();
    fetchAddress();
  }, [userdata]);

  // componentdidmount
  useEffect(() => {
    getallproandrev();
  },[]);

  // offer transaction
  useEffect(() => {
    for (let i = 0; i < offerTransaction.length; i++) {
      const element = offerTransaction[i];

      if (element.offer_validity.includes("days")) {
        let validity = parseInt(element.offer_validity.replace(" days", ""));
        let otdate = new Date(element.ot_date);
        let expiry_date = new Date(element.ot_date);
        expiry_date.setDate(expiry_date.getDate() + validity);
        let current_date = new Date();
        // console.log(otdate);
        // console.log(expiry_date);
        // console.log(current_date);
        if (
          current_date.getTime() <= expiry_date.getTime() &&
          current_date.getTime() > otdate.getTime()
        ) {
          setOfferValid(true);
          setActiveOffer(element);
        } else {
        }
      }
    }
  }, [offerTransaction]);
  
  return (
    <Context.Provider
      value={{
        washprogram,
        setWashProgram,
        timeslot,
        setTimeSlot,
        sendDataToApp,
        userdata,
        setUserData,
        pageLoading,
        allAddress,
        addAddress,
        pickupAdd,
        setPickupAdd,
        allwashPrograms,
        washprogramLoading,
        washprogramError,
        offerTransaction,
        activeOffer,
        setActiveOffer,
        offerValid,
        setOfferValid,
        allReviews,
        hreviewloading,
        addReview,
        logError,
        addingReviewLoading,
        getAllReviews,
        activeWashProgram,
        setActiveWashProgram,
        addOrder,
        fetchAddress,
        hreviewError,
        setAllReviews,
        sethreviewloading,
        signupUser,
        signupFieldDetails,
        setSignupFieldDetails,
        signupErrorDetails,
        setSignupErrors,
        signupconfirmRes,
        setSignupConfirmRes,
        fpUser,
        setFpUser,
        setWindowSize,
        windowSize,
        selectedProgram,
        setSelectedProgram,
        variations,
        programRate,
        setProgramRate,
        detergent,
        setDetergent,
        setConditioner,
        conditioner,
        setIronType,
        ironType,
        orderStage,
        setOrderStage,
        addOrderRequest
      }}
    >
      {props.children}
    </Context.Provider>
  );
}
