import React, { useState, useRef } from "react";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import Address from "../stagescomponents/Address";
import GuestSection from "./GuestSection";
import NewAddressSection from "./NewAddressSection";

export default function PickupaddressAccordian() {
  const context = useContext(AppContext);
  const {
    pickupAdd,
    userdata,
    allAddress,
    setPickupAdd,
    guestAddress,
    setGuestAddress,
    guestMode,
    setGuestMode,
    sendDataToApp,
  } = context;

  const [guestErrors, setGuestErrors] = useState({
    name: false,
    mobile: false,
    address1: false,
    address2: false,
    landmark: false,
    pincode: false,
    city: false,
    state: false,
  });
  const cancel_guest = useRef();
  const pickupadd_btn = useRef();
  const pickupadd_body = useRef();

  function pickupAddFunc(aid) {
    setPickupAdd(aid);

    pickupadd_btn.current.classList.add("collapsed");
    pickupadd_btn.current.setAttribute("aria-expanded", "false");
    pickupadd_body.current.classList.remove("show");
  }
  function guestChange(e) {
    setGuestAddress((state) => {
      return { ...state, [e.target.id]: e.target.value };
    });
  }
  const guestsubmit = async () => {
    // resetting errors
    setGuestErrors({
      name: false,
      mobile: false,
      address1: false,
      address2: false,
      landmark: false,
      pincode: false,
      city: false,
      state: false,
    });

    let nameErr = false;
    let mobileErr = false;
    let address1Err = false;
    let address2Err = false;
    let landmarkErr = false;
    let pincodeErr = false;
    let cityErr = false;
    let stateErr = false;

    // validations
    if (guestAddress.name == "" || guestAddress.name.length < 3) {
      setGuestErrors(function (state) {
        return { ...state, name: true };
      });
      nameErr = true;
    } else {
      nameErr = false;
    }
    if (
      guestAddress.mobile == "" ||
      guestAddress.mobile.length < 10 ||
      guestAddress.mobile.length > 10
    ) {
      mobileErr = true;
      setGuestErrors(function (state) {
        return { ...state, mobile: true };
      });
    } else {
      mobileErr = false;
    }
    if (guestAddress.address1 == "" || guestAddress.address1.length < 3) {
      address1Err = true;
      setGuestErrors(function (state) {
        return { ...state, address1: true };
      });
    } else {
      address1Err = false;
    }
    if (guestAddress.address2 == "" || guestAddress.address2.length < 3) {
      address2Err = true;
      setGuestErrors(function (state) {
        return { ...state, address2: true };
      });
    } else {
      address2Err = false;
    }
    if (guestAddress.landmark == "" || guestAddress.landmark.length < 3) {
      landmarkErr = true;
      setGuestErrors(function (state) {
        return { ...state, landmark: true };
      });
    } else {
      landmarkErr = false;
    }
    if (
      guestAddress.pincode == "" ||
      guestAddress.pincode.length < 6 ||
      guestAddress.pincode.length > 6
    ) {
      pincodeErr = true;
      setGuestErrors(function (state) {
        return { ...state, pincode: true };
      });
    } else {
      pincodeErr = false;
    }
    if (guestAddress.city == "" || guestAddress.city.length < 3) {
      cityErr = true;
      setGuestErrors(function (state) {
        return { ...state, city: true };
      });
    } else {
      cityErr = false;
    }
    if (guestAddress.state == "" || guestAddress.state.length < 3) {
      stateErr = true;
      setGuestErrors(function (val) {
        return { ...val, state: true };
      });
    } else {
      stateErr = false;
    }

    if (
      !nameErr &&
      !mobileErr &&
      !address1Err &&
      !address2Err &&
      !landmarkErr &&
      !pincodeErr &&
      !cityErr &&
      !stateErr
    ) {
      setGuestMode(true);
      cancel_guest.current.click();
      pickupadd_btn.current.classList.add("collapsed");
      pickupadd_btn.current.setAttribute("aria-expanded", "false");
      pickupadd_body.current.classList.remove("show");
    } else {
      setGuestMode(false);
    }
  };
  return (
    <div className="accordion-item delivery-accordion">
      <h2 className="accordion-header" id="headingThree">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="false"
          aria-controls="collapseThree"
          ref={pickupadd_btn}
          style={{ fontSize: 14 }}
        >
          <span>
            Pickup & Delivery :
            <span style={{ fontWeight: "600", marginLeft: 3, fontSize: 14 }}>
              {pickupAdd ? `Address id - ${pickupAdd}` : ""}
            </span>
            <span style={{ marginLeft: 3, fontSize: 14 }}>
              {" "}
              {pickupAdd == null && guestMode == false
                ? "Select a pickup & delivery address"
                : ""}
            </span>
            <span style={{ marginLeft: 3, fontWeight: "600", fontSize: 14 }}>
              {" "}
              {guestMode ? "Guest Mode" : ""}
            </span>
          </span>
        </button>
      </h2>
      <div
        id="collapseThree"
        className="accordion-collapse collapse"
        aria-labelledby="headingThree"
        data-bs-parent="#accordionExample"
        ref={pickupadd_body}
      >
        <div className="accordion-body">
          <div>
            {userdata.loggedin !== false && <NewAddressSection />}

            <div className="stage-address-wrapper">
              {userdata.loggedin === true &&
                allAddress.map((item) => {
                  return (
                    <div
                      className={`address-wrapper p-2 my-2 border ${
                        pickupAdd === item.addressid ? "border-primary" : ""
                      }`}
                      key={item.addressid}
                    >
                      <Address data={item} setpickup={pickupAddFunc} />
                    </div>
                  );
                })}
              {allAddress.length == 0 && userdata.loggedin === true && (
                <div className="simpleflex py-3 text-muted">
                  <span style={{ fontSize: 14 }}>
                    You have no saved address
                  </span>
                </div>
              )}
              {userdata.loggedin === false && guestMode == false && (
                <GuestSection
                  pickupBody={pickupadd_body}
                  pickupBtn={pickupadd_btn}
                />
              )}
              {guestMode && (
                <div>
                  <button
                    type="button"
                    className="btn w-100 my-2"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      fontSize: 14,
                    }}
                    onClick={() => {
                      let datatosend = { login: "navigate to login" };
                      sendDataToApp(datatosend);
                    }}
                  >
                    Login
                  </button>
                  <div className="text-center">
                    <span className="text-muted" style={{ fontSize: 14 }}>
                      or
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-dark w-100 my-2"
                    data-bs-toggle="modal"
                    data-bs-target="#ChangeguestModal"
                    style={{ fontSize: 14 }}
                  >
                    Change guest address
                  </button>
                  <div
                    className="modal fade"
                    id="ChangeguestModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalLabel"
                            style={{ fontSize: 23 }}
                          >
                            Guest Checkout
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div
                            className="simpleflex"
                            style={{
                              flexDirection: "column",
                            }}
                          >
                            <div className="area-warning-wrapper px-2 py-2">
                              <div
                                className=" px-2 py-2"
                                style={{
                                  borderRadius: 10,
                                  backgroundColor: "#f5f5dc ",
                                  display: "flex",
                                }}
                              >
                                <div
                                  className="simpleflex"
                                  style={{ width: 50 }}
                                >
                                  <img
                                    src={require("../../assets/warning.png")}
                                    alt="Warning"
                                    style={{ height: 25, width: 25 }}
                                  />
                                </div>
                                <span
                                  style={{
                                    color: "black",
                                    flex: 1,
                                    textAlign: "left",
                                  }}
                                >
                                  Currently operating in{" "}
                                  <strong>Sector-1 & Manesar</strong> only, if
                                  you live outside our serviceable zone, please
                                  wait & we will serve you soon!
                                </span>
                              </div>
                            </div>
                            <span
                              className="text-center"
                              style={{ fontSize: 23 }}
                            >
                              Instead as a guest, create an account and enjoy premium laundry service
                            </span>
                            <button
                              className="btn btn-outline-dark my-2"
                              style={{ width: "92% ", fontSize: 14 }}
                              onClick={() => {
                                let datatosend = {
                                  signup: "navigate to signup",
                                };
                                sendDataToApp(datatosend);
                              }}
                            >
                              Create an account
                            </button>
                          </div>
                          <div className="guest-form-wrapper">
                            <div className="form-wrapper">
                              <div
                                className="input-wrapper my-3  py-2"
                                style={{
                                  position: "relative",
                                  marginLeft: "3%",
                                }}
                              >
                                <label
                                  htmlFor="name"
                                  className="px-1 add-address-label"
                                  style={{
                                    position: "absolute",
                                    top: -3,
                                    left: 12,
                                    backgroundColor: "white",
                                    fontSize: 14,
                                    color: guestErrors.name ? "red" : "grey",
                                  }}
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  className="py-2 px-2 add-address-inp form-control"
                                  style={{
                                    width: "95%",
                                    fontSize: 14,
                                    borderColor: guestErrors.name
                                      ? "red"
                                      : "grey",
                                  }}
                                  placeholder="Type your full name"
                                  value={guestAddress.name}
                                  id="name"
                                  onChange={guestChange}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    paddingTop: 3,
                                    fontSize: 14,
                                    display: guestErrors.name ? "flex" : "none",
                                  }}
                                >
                                  Name value is invalid!
                                </span>
                              </div>
                              <div
                                className="input-wrapper my-3  py-2"
                                style={{
                                  position: "relative",
                                  marginLeft: "3%",
                                }}
                              >
                                <label
                                  htmlFor="name"
                                  className="px-1 add-address-label"
                                  style={{
                                    position: "absolute",
                                    top: -3,
                                    left: 12,
                                    backgroundColor: "white",
                                    fontSize: 14,
                                    color: guestErrors.mobile ? "red" : "grey",
                                  }}
                                >
                                  Mobile
                                </label>
                                <input
                                  type="number"
                                  name="name"
                                  className="py-2 px-2 add-address-inp form-control"
                                  style={{
                                    width: "95%",
                                    fontSize: 14,
                                    borderColor: guestErrors.mobile
                                      ? "red"
                                      : "grey",
                                  }}
                                  placeholder="Ex - 9876543210"
                                  value={guestAddress.mobile}
                                  id="mobile"
                                  onChange={guestChange}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    paddingTop: 3,
                                    fontSize: 14,
                                    display: guestErrors.mobile
                                      ? "flex"
                                      : "none",
                                  }}
                                >
                                  Please enter a valid mobile number without any
                                  country code !
                                </span>
                              </div>
                              <div
                                className="input-wrapper my-3  py-2"
                                style={{
                                  position: "relative",
                                  marginLeft: "3%",
                                }}
                              >
                                <label
                                  htmlFor="name"
                                  className="px-1 add-address-label"
                                  style={{
                                    position: "absolute",
                                    top: -3,
                                    left: 12,
                                    backgroundColor: "white",
                                    fontSize: 14,
                                    color: guestErrors.address1
                                      ? "red"
                                      : "grey",
                                  }}
                                >
                                  Address 1
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  className="py-2 px-2 add-address-inp form-control"
                                  style={{
                                    width: "95%",
                                    fontSize: 14,
                                    borderColor: guestErrors.address1
                                      ? "red"
                                      : "grey",
                                  }}
                                  placeholder="House No./ Flat No. / Tower No."
                                  value={guestAddress.address1}
                                  id="address1"
                                  onChange={guestChange}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    paddingTop: 3,
                                    fontSize: 14,
                                    display: guestErrors.address1
                                      ? "flex"
                                      : "none",
                                  }}
                                >
                                  Address 1 value is invalid!
                                </span>
                              </div>
                              <div
                                className="input-wrapper my-3  py-2"
                                style={{
                                  position: "relative",
                                  marginLeft: "3%",
                                }}
                              >
                                <label
                                  htmlFor="name"
                                  className="px-1 add-address-label"
                                  style={{
                                    position: "absolute",
                                    top: -3,
                                    left: 12,
                                    backgroundColor: "white",
                                    fontSize: 14,
                                    color: guestErrors.address2
                                      ? "red"
                                      : "grey",
                                  }}
                                >
                                  Address 2
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  className="py-2 px-2 add-address-inp form-control"
                                  style={{
                                    width: "95%",
                                    fontSize: 14,
                                    borderColor: guestErrors.address2
                                      ? "red"
                                      : "grey",
                                  }}
                                  placeholder="Society Name, Sector Name, Village Name"
                                  value={guestAddress.address2}
                                  id="address2"
                                  onChange={guestChange}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    paddingTop: 3,
                                    fontSize: 14,
                                    display: guestErrors.address2
                                      ? "flex"
                                      : "none",
                                  }}
                                >
                                  Address 2 value is invalid!
                                </span>
                              </div>
                              <div
                                className="input-wrapper my-3  py-2"
                                style={{
                                  position: "relative",
                                  marginLeft: "3%",
                                }}
                              >
                                <label
                                  htmlFor="name"
                                  className="px-1 add-address-label"
                                  style={{
                                    position: "absolute",
                                    top: -3,
                                    left: 12,
                                    backgroundColor: "white",
                                    fontSize: 14,
                                    color: guestErrors.landmark
                                      ? "red"
                                      : "grey",
                                  }}
                                >
                                  Landmark
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  className="py-2 px-2 add-address-inp form-control"
                                  style={{
                                    width: "95%",
                                    fontSize: 14,
                                    borderColor: guestErrors.landmark
                                      ? "red"
                                      : "grey",
                                  }}
                                  placeholder="Famous place near you"
                                  value={guestAddress.landmark}
                                  id="landmark"
                                  onChange={guestChange}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    paddingTop: 3,
                                    fontSize: 14,
                                    display: guestErrors.landmark
                                      ? "flex"
                                      : "none",
                                  }}
                                >
                                  Landmark value is invalid!
                                </span>
                              </div>
                              <div className="simpleflex">
                                <span className="text-muted small">
                                  You can't change pincode, city & state
                                </span>
                              </div>
                              <div
                                className="input-wrapper my-3  py-2"
                                style={{
                                  position: "relative",
                                  marginLeft: "3%",
                                }}
                              >
                                <label
                                  htmlFor="name"
                                  className="px-1 add-address-label"
                                  style={{
                                    position: "absolute",
                                    top: -3,
                                    left: 12,
                                    backgroundColor: "white",
                                    fontSize: 14,
                                    color: "grey",
                                  }}
                                >
                                  Pincode
                                </label>
                                <input
                                  type="number"
                                  name="name"
                                  className="py-2 px-2 add-address-inp form-control"
                                  style={{ width: "95%", fontSize: 14 }}
                                  placeholder="Your pincode"
                                  value={guestAddress.pincode}
                                  id="pincode"
                                  readOnly
                                  disabled
                                />
                                <span
                                  style={{
                                    color: "red",
                                    paddingTop: 3,
                                    fontSize: 14,
                                    display: guestErrors.pincode
                                      ? "flex"
                                      : "none",
                                  }}
                                >
                                  Pincode value is invalid!
                                </span>
                              </div>
                              <div
                                className="input-wrapper my-3  py-2"
                                style={{
                                  position: "relative",
                                  marginLeft: "3%",
                                }}
                              >
                                <label
                                  htmlFor="name"
                                  className="px-1 add-address-label"
                                  style={{
                                    position: "absolute",
                                    top: -3,
                                    left: 12,
                                    backgroundColor: "white",
                                    fontSize: 14,
                                    color: "grey",
                                  }}
                                >
                                  City
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  className="py-2 px-2 add-address-inp form-control"
                                  style={{ width: "95%", fontSize: 14 }}
                                  placeholder="Your city"
                                  value={guestAddress.city}
                                  id="city"
                                  readOnly
                                  disabled
                                />
                                <span
                                  style={{
                                    color: "red",
                                    paddingTop: 3,
                                    fontSize: 14,
                                    display: guestErrors.city ? "flex" : "none",
                                  }}
                                >
                                  City value is invalid!
                                </span>
                              </div>
                              <div
                                className="input-wrapper my-3  py-2"
                                style={{
                                  position: "relative",
                                  marginLeft: "3%",
                                }}
                              >
                                <label
                                  htmlFor="name"
                                  className="px-1 add-address-label"
                                  style={{
                                    position: "absolute",
                                    top: -3,
                                    left: 12,
                                    backgroundColor: "white",
                                    fontSize: 14,
                                    color: "grey",
                                  }}
                                >
                                  State
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  className="py-2 px-2 add-address-inp form-control"
                                  style={{ width: "95%", fontSize: 14 }}
                                  placeholder="Your state"
                                  value={guestAddress.state}
                                  id="state"
                                  readOnly
                                  disabled
                                />
                                <span
                                  style={{
                                    color: "red",
                                    paddingTop: 3,
                                    fontSize: 14,
                                    display: guestErrors.state
                                      ? "flex"
                                      : "none",
                                  }}
                                >
                                  State value is invalid!
                                </span>
                              </div>
                            </div>

                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ flexDirection: "column" }}
                            >
                              <button
                                type="button"
                                className="btn my-2"
                                onClick={guestsubmit}
                                style={{
                                  fontSize: 14,
                                  width: "95%",
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                Save changes
                              </button>
                              <button
                                type="button"
                                className="btn"
                                data-bs-dismiss="modal"
                                ref={cancel_guest}
                                style={{
                                  fontSize: 14,
                                  width: "95%",
                                  borderColor: "black",
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
