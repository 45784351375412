import React, { useState } from "react";
import app from "../../firebaseConfig";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../context/AppContext"
import { useNavigate } from "react-router-dom";
export default function Signup(props) {
  const auth = getAuth(app);
  const context  = useContext(AppContext);
  const {sendDataToApp,setSignupFieldDetails,signupFieldDetails,signupErrorDetails,setSignupErrors,signupUser,signupconfirmRes,setSignupConfirmRes} = context;
  const [pageStage,setPageStage] = useState(1);
  const [stageOneLoader,setStageOneLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(()=>{
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-buttons",
        {
          size: "invisible",
          callback: (response) => {
          },
          "expired-callback": (res) => {
            sendDataToApp({
              alert : {
                type : "red",
                message : "This is a development error, complaint it at complaint@shortwash.com or restart the app",
                duration : 5000
              }
            })
            console.log('recaptch expired');
          },
        },
        auth
      )
    } catch (error) {
      sendDataToApp({
        alert : {
          type : "red",
          message : "This is a development error, complaint it at complaint@shortwash.com or restart the app",
          duration : 5000
        }
      })
      console.log('recaptch error - ' + JSON.stringify(error.message));
    }
  },[])  

  function onchange(e) {
    if (e.target.id == 'mobile') {
      if (signupconfirmRes) {
        setSignupConfirmRes(null);
      }
      if (e.target.value.length <= 10) {
        setSignupFieldDetails({
          ...signupFieldDetails,
          [e.target.id]: e.target.value,
        });
      }
    }else{
      setSignupFieldDetails({
        ...signupFieldDetails,
        [e.target.id]: e.target.value,
      });
    }
  }
  
  const stageOne = async () => {
    try {
      setSignupErrors({
        password: false,
        name: false,
        mobileLen: false,
        mobileExists: false,
      });
  
      let mobileErr = false;
      let nameErr = false;
      let passwordErr = false;
  
      // validating name field
      if (signupFieldDetails.name.length <= 1) {
        // enter a valid name
        setSignupErrors((stat) => {
          return { ...stat, name: true };
        });
        nameErr = true;
      } else {
        nameErr = false;
      }
      // validating mobile field
      if (signupFieldDetails.mobile.length < 10 || signupFieldDetails.mobile.length > 10) {
        // enter a valid mobile
        setSignupErrors((stat) => {
          return { ...stat, mobileLen: true };
        });
        mobileErr = true;
      } else {
        mobileErr = false;
      }
      // validating password field
      if (signupFieldDetails.password.length < 6) {
        // enter a valid name
        setSignupErrors((stat) => {
          return { ...stat, password: true };
        });
        passwordErr = true;
      } else {
        passwordErr = false;
      }
  
      if (!nameErr && !mobileErr && !passwordErr) {
        setStageOneLoader(true);
        let mobile = signupFieldDetails.mobile;
        
        let sqlQuery = `SELECT mobile from users where mobile = ${mobile}`;
        let dbUrl = "https://express.shortwash.com/getters/getsingle.php";
        let response = await fetch(dbUrl,{
          method : 'POST',
          headers : {
            'Content-Type' : "applicaiton/json"
          },
          body : JSON.stringify({
            query : sqlQuery
          })
        });
        let responseData = await response.json();
        
        if (responseData.response == 'success') {
          setStageOneLoader(false);
          setSignupErrors((stats)=>{
            return {...stats,mobileExists : true}
          });
        }else {
          setStageOneLoader(false);
          setPageStage('loading');
          signInWithPhoneNumber(
            auth,
            `+91${mobile}`,
            window.recaptchaVerifier
          )
            .then((confirmationResult) => {
  
  
              // user in with confirmationResult.confirm(code).
              setSignupConfirmRes(confirmationResult);
              setTimeout(() => {
                sendDataToApp({alert : {
                  type : "green",
                  message : `SMS sent successfully on +91${mobile}`,
                  duration : 5000
                }})
                navigate('/verification');
              }, 1000);
            })
            .catch((error) => {
              sendDataToApp({alert : {
                type : "red",
                message : `Something went wrong while sending SMS on +91${mobile}, please try again`,
                duration : 5000
              }})
              console.log('sms not sent - ' + JSON.stringify(error.message));
              pageReset();
              // we had to reset recaptcha to use it again
            });
  
        }  
      }
    } catch (error) {
      // error
    }
    
  };
  const pageReset = async()=>{
    // setting confirm response to null
    setSignupConfirmRes(null)
    // getting back to stage 1
    setPageStage(1);
    // resetting fields from stage 1
    setSignupFieldDetails({
      name: "",
      mobile: "",
      email: "",
      password: "",
      referral: "",
      verification:""
    });
    window.recaptchaVerifier.render().then(function(widgetId) {
      window.grecaptcha.reset(widgetId);
    });
  }
  

  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "100%" }} className="container">
        {pageStage != 'loading' && <div
          style={{
            backgroundColor: "white",
          }}
        >
          <div className="text-center">
            <span
              style={{
                marginTop: 20,
                marginLeft: 20,
                marginRight : 20,
                fontSize: 32,
                fontWeight: "300",
              }}
              className="text-center text-dark"
            >
              Create an account and enjoy premium laundry service
            </span>
          </div>
          <div
            style={{ width: "100%", alignItems: "center", marginVertical: 5 }}
            className="simpleflex"
          >
            <img
              style={{ width: "70%", height: 200 }}
              src={require("../../assets/signup/signupbg.png")}
              alt="Signup Background"
            />
          </div>
          <div
            style={{
              marginHorizontal: 20,
              marginTop: 5,
              marginBottom: 30,
            }}
          >
            <div style={{ position: "relative" }}>
              <span
                style={{
                  fontSize: 15,
                  color: "grey",
                  position: "absolute",
                  backgroundColor: "white",
                  paddingLeft: 5,
                  paddingRight: 5,
                  zIndex: 1,
                  left: 12,
                  top: -10,
                  color: signupErrorDetails.name ? "red" : "grey",
                }}
              >
                Name
              </span>
              <input
                style={{
                  borderColor: "rgb(225,225,225)",
                  borderWidth: 1,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 15,
                  paddingBottom: 15,
                  color: "black",
                  fontSize: 16,
                  borderColor: signupErrorDetails.name ? "red" : "rgb(225,225,225)",
                  width: "100%",
                }}
                placeholder="Your sweet name"
                value={signupFieldDetails.name}
                id="name"
                className="form-control"
                onChange={onchange}
              />
            </div>
              {/*error*/}
            <div
                  style={
                    { display: signupErrorDetails.name ? "flex" : "none",flexDirection : 'row',color : 'red',marginTop : 5,alignItems : 'center' }
                  }
                >
                  <img
                    style={{
                      height: 12,
                      width: 12,
                      marginRight: 5
                    }}
                    src={require("../../assets/signup/info_red.png")}
                  />
                  <span style={{color : 'red',fontSize : 14}}>
                  
                  Invalid name
                  </span>
                </div>
            <div style={{ position: "relative", marginTop: 20 }}>
              <span
                style={{
                  fontSize: 15,
                  color: "grey",
                  position: "absolute",
                  backgroundColor: "white",
                  paddingLeft: 5,
                  paddingRight: 5,
                  zIndex: 1,
                  left: 12,
                  top: -10,
                  color:
                    signupErrorDetails.mobileLen || signupErrorDetails.mobileExists
                      ? "red"
                      : "grey",
                }}
              >
                Mobile
              </span>
              <input
                style={{
                  borderColor: "rgb(225,225,225)",
                  borderWidth: 1,
                  marginTop: 6,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 15,
                  paddingBottom: 15,
                  color: "black",
                  width: "100%",
                  fontSize: 16,
                  borderColor:
                    signupErrorDetails.mobileLen || signupErrorDetails.mobileExists
                      ? "red"
                      : "rgb(225,225,225)",
                }}
                placeholder="Your primary number"
                id="mobile"
                type="number"
                className="form-control"
                value={signupFieldDetails.mobile}
                onChange={onchange}
              />
            </div>

                {/*error*/}
            <div
                  style={
                    { display: signupErrorDetails.mobileLen ? "flex" : "none",flexDirection : 'row',color : 'red',marginTop : 5,alignItems : 'center' }
                  }
                >
                  <img
                    style={{
                      height: 12,
                      width: 12,
                      marginRight: 5
                    }}
                    src={require("../../assets/signup/info_red.png")}
                  />
                  <span style={{color : 'red',fontSize : 14}}>
                  
                  Mobile number should be of 10 digits
                  </span>
                </div>
                <div
                style={
                  { display: signupErrorDetails.mobileExists ? "flex" : "none",flexDirection : 'row',color : 'red',marginTop : 5,alignItems : 'center' }
                }
              >
                <img
                  style={{
                    height: 12,
                    width: 12,
                    marginRight: 5
                  }}
                  src={require("../../assets/signup/info_red.png")}
                />
                <span style={{color : 'red',fontSize : 14}}>
                
                User already exists, raise a complaint at <strong>complaint@shortwash.com</strong>
                </span>
              </div>
            
            <div style={{ position: "relative", marginTop: 20 }}>
              <span
                style={{
                  fontSize: 15,
                  color: "grey",
                  position: "absolute",
                  backgroundColor: 'white',
                  paddingLeft: 5,
                  paddingRight: 5,
                  zIndex: 1,
                  left: 12,
                  top: -10,
                  color: signupErrorDetails.password ? "red" : "grey",
                }}
              >
                Password
              </span>
              <input
                style={{
                  borderColor: "rgb(225,225,225)",
                  borderWidth: 1,
                  marginTop: 6,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 15,
                  paddingBottom: 15,
                  color: "black",
                  fontSize: 16,
                  width: "100%",
                  borderColor: signupErrorDetails.password
                    ? "red"
                    : "rgb(225,225,225)",
                }}
                placeholder="Your dangerous password"
                id="password"
                type="password"
                className="form-control"
                value={signupFieldDetails.password}
                onChange={onchange}
              />
              <div
                  style={
                    { display: signupErrorDetails.password ? "flex" : "none",flexDirection : 'row',color : 'red',marginTop : 5,alignItems : 'center' }
                  }
                >
                  <img
                    style={{
                      height: 12,
                      width: 12,
                      marginRight: 5
                    }}
                    src={require("../../assets/signup/info_red.png")}
                  />
                  <span style={{color : 'red',fontSize : 14}}>
                  
                  Password must be of atleast 6 characters
                  </span>
                </div>
            </div>

            <div style={{ position: "relative", marginTop: 20 }}>
              <span
                style={{
                  fontSize: 15,
                  color: "grey",
                  position: "absolute",
                  backgroundColor: 'white',
                  paddingLeft: 5,
                  paddingRight: 5,
                  zIndex: 1,
                  left: 12,
                  top: -10,
                }}
              >
                Referral Code (Optional)
              </span>
              <input
                style={{
                  borderColor: "rgb(225,225,225)",
                  borderWidth: 1,
                  marginTop: 6,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 15,
                  paddingBottom: 15,
                  width: "100%",
                  color: "black",
                  fontSize: 16,
                }}
                placeholder="Your friend's referral code"
                className="form-control"
                id = "referral"
                value={signupFieldDetails.referral}
                onChange={onchange}
              />
            </div>
            {stageOneLoader && <div className="simpleflex " style={{marginTop : 30}}>
            <img src={require('../../assets/stages/pageLoader.gif')} alt="Page Loading Animation" />
            </div>}
            <button
              className="btn w-100"
              style={{
                backgroundColor: "red",
                color: "white",
                marginTop: 30,
                borderRadius: 5,
              }}
              onClick={stageOne}
            >
              Sign up
            </button>

            <div
              className="text-center"
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              <span className="text-muted text-center">or</span>
            </div>
            <button className="btn btn-outline-dark w-100" onClick={()=>{
              sendDataToApp({
                login : true
              })
            }}>
              Already have account?
            </button>
          </div>
        </div>}


        {pageStage == 'loading' && <div className="simpleflex" style={{height : '100vh'}}>
        <img src={require('../../assets/stages/pageLoader.gif')} alt="Page Loading Animation" />
        </div>}
        <div id="sign-in-buttons"></div>

      </div>
    </React.Fragment>
  );
}
