import React from 'react'
import { useContext } from 'react';
import FacebookIcon from "../../assets/social/facebook.png";
import InstagramIcon from "../../assets/social/instagram.png";
import TwitterIcon from "../../assets/social/twitter.png";
import WhatsappIcon from "../../assets/social/whatsapp.png";
import AppContext from '../../context/AppContext';

export default function SocialMedia() {
  const context = useContext(AppContext);
  const {sendDataToApp} = context;

  function socialClicked(social){
    let data = {};
    switch (social) {
      case 'Facebook':
        data = {social : 'Facebook',Link : "https://www.facebook.com/people/ShortWash/100089193737874/"};
        break;
      case 'Instagram':
        data = {social : 'Instagram',Link : "https://www.instagram.com/official_shortwash/"};
        break;
      case 'Twitter':
        data = {social : 'Twitter',Link : "https://twitter.com/shortwash"};
        break;
      case 'Whatsapp':
      data = {social : 'Whatsapp',Link : "https://wa.me/917838106525"};
      break;
      default:
        data = {social : 'None', Link : 'none'}
        break;
    }
    sendDataToApp(data);
    // console.log(data);
  }
  return (
    <div className="social-handles-inner-wrapper w-100 d-flex justify-content-center align-items-center">
              
              <img
                src={FacebookIcon}
                alt="Facebook Icon"
                style={{ marginRight: 15 }}
                onClick={()=>{
                  socialClicked('Facebook')
                }}
              />
              <img
                src={InstagramIcon}
                alt="Instagram Icon"
                style={{ marginRight: 15 }}
                onClick={()=>{
                  socialClicked('Instagram')
                }}
              />
              <img
                src={TwitterIcon}
                alt="Twitter Icon"
                style={{ marginRight: 15,display:'none' }}
                onClick={()=>{
                  socialClicked('Twitter')
                }}
              />
              <img
                src={WhatsappIcon}
                alt="Whatsapp Icon"
                style={{ marginRight: 15 }}
                onClick={()=>{
                  socialClicked('Whatsapp')
                }}
              />
            </div>
  )
}
