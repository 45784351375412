import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReviewUser from "../../assets/review/review_user.png";
import ReviewUser2 from "../../assets/review/review_user2.png";
import ReviewUser3 from "../../assets/review/review_user3.png";
import Star from "../../assets/review/star16.png";
import BlankStar from "../../assets/review/star_blank16.png";

export default function UserReview({ data }) {
  let userImg = [ReviewUser, ReviewUser2, ReviewUser3];
  let [randNum,setRandNum] = useState(null);
useEffect(()=>{
  setRandNum(Math.floor(Math.random() * 3))
},[])
  function formatDate(parm) {
    let date = parm.substr(0, 10);
    let dateArr = date.split("-");
    let month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let finalDate = `${month[dateArr[1] - 1]} ${dateArr[2]}, ${dateArr[0]}`;
    return finalDate;
  }
  return (
    <div className="card user-review" style={{border : 0}}> 
      <div className="row g-0">
        <div className="col-3 simpleflex">
          <img src={userImg[randNum]} className="img-fluid" alt="User Logo" />
        </div>
        <div className="col-9">
          <div className="card-body">
            <span className="card-title">{data.name}</span>
            <div className="star-wrapper">
              <img
                src={data.star_rating >= 1 ? Star : BlankStar}
                alt="Review Star"
                style={{ marginRight: 3 }}
              />
              <img
                src={data.star_rating >= 2 ? Star : BlankStar}
                alt="Review Star"
                style={{ marginRight: 3 }}
              />
              <img
                src={data.star_rating >= 3 ? Star : BlankStar}
                alt="Review Star"
                style={{ marginRight: 3 }}
              />
              <img
                src={data.star_rating >= 4 ? Star : BlankStar}
                alt="Review Star"
                style={{ marginRight: 3 }}
              />
              <img
                src={data.star_rating >= 5 ? Star : BlankStar}
                alt="Review Star"
                style={{ marginRight: 3 }}
              />
            </div>
            <p className="card-text" style={{ margin: 0 }}>
              {data.message}
            </p>
            <p className="card-text">
              <small className="text-muted">
                posted on {formatDate(data.review_added)}
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
